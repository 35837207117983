@import './src/scss/variables';
@import './src/scss/game';

$grid_cell_size: calc(131px - (var(--grid-size) * 8px));

:root {
  --grid-size: 4;
}

$max_grid_size: 8;

.grid {
  position: relative;
  border: 1px solid;

  &__row {
    display: flex;
    margin: 0;

    &:first-child {
      .grid__cell {
        border-top: none;
      }
    }

    &:last-child {
      .grid__cell {
        border-bottom: none;
      }
    }

    .grid__cell {
      &:first-child {
        border-left: none;
      }

      &:last-child {
        border-right: none;
      }
    }
  }

  &__cell {
    width: $grid_cell_size;
    aspect-ratio: 1 / 1;
    border-right: 1px solid;
    border-bottom: 1px solid;
    box-sizing: content-box !important;
    position: relative;

    &:last-child {
      margin-right: 0;
    }
  }
}

.game__content {
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  cursor: default;
  user-select: none;
}

.game__container {
  position: relative;
  flex-direction: column;
  align-items: center;
}

.grid {
  .grid__cell {
    cursor: pointer;
    font-size: 3rem;
    text-align: center;
    line-height: $grid_cell_size;

    &.selected {
      &::after {
        top: 0;
        left: 0;
        content: "";
        box-sizing: border-box;
        position: absolute;
        border: 3px solid blue;
        border-radius: 50%;
        width: 90%;
        aspect-ratio: 1 / 1;
        margin: 5%;
      }
    }
  }
}