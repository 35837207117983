@import './src/scss/variables';
@import './src/scss/app';
@import './src/scss/breadcrumbs';
@import './game2';

.emoji {
  &__title {
		text-align: center;
    margin-top: 2rem;
    font-size: $font-size5;
    font-weight: 600;
  }

  &__categories {
    margin-top: 2rem;

    &-list {
	    display: flex;
	    justify-content: center;
	    flex-wrap: wrap;
	    gap: 1rem;
    }

	  a {
	    @include text-decoration;
		}

	  .active a {
	    text-decoration: none;
	    pointer-events: none;
	  }
  }

  &__colors {
    &_inner {
      margin-top: 2rem;
    }

    &-list {
	    display: flex;
	    justify-content: center;
	    gap: 1rem;
    }
  }

  &__items {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    gap: 2rem;

    &_inner {
      margin-top: 2rem;
    }
  }

  &__item {
		display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;

    &-title {
      font-size: $font-size3;
    }

    .char {
      font-size: 3rem;
      font-family: 'Apple Color Emoji', sans-serif;
    }

    &-list {
	    display: flex;
	    flex-direction: row;
	    justify-content: center;
	    flex-wrap: wrap;
	    line-height: 1;
	    gap: .5rem 2rem;

			.detail {
				opacity: 0;
				pointer-events: none;
			}

			li {
				display: flex;
			  flex-direction: column;
			  align-items: center;
			  gap: .5rem;

			  &:hover {
			    .detail {
						opacity: 1;
						pointer-events: auto;
			    }
			  }
			}

			&_inner {
				gap: 2.25rem 2rem;
			}
    }
  }

	&__tab {
	  margin-top: 2rem;

		a {
	    display: inline;
	    margin: 0 .5rem;
	    @include text-decoration;
		}

		.widget__input {
      max-width: 100%;
      padding: 0;
      background: $color2;
		}

		.widget__input-group {
      width: 100%;
      max-width: 100%;
		}

		.widget__input-number {
			width: 100%;
			font-size: $font-size3;
			background: $color2;
			font-family: 'Apple Color Emoji', sans-serif;
		}
	}
}

.circle {
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  background-color: #fed75a;

  &.color-1F3FB {
    background-color: #ffdebb;
  }

  &.color-1F3FC {
    background-color: #eabe96;
  }

  &.color-1F3FD {
    background-color: #c88d61;
  }

  &.color-1F3FE {
    background-color: #a86536;
  }

  &.color-1F3FF {
    background-color: #5e4539;
  }
}

.loading {
  background: url(../assets/loader.gif) no-repeat center;
  display: block;
  width: 100px;
  height: 100px;
  margin: 0 auto;
  margin-top: 20px;
}

.info-container__item:first-child {
	padding-bottom: 0;
  margin-bottom: 0;
  border-bottom: 0;
}